<template>

  <div class="list-view-group" v-if="collectionsWithPermissions.length>0">
    <div class="list-view-group-heading" @click="toggleExpanded">{{العنوان}}
      <span v-if="unfolded" class="icon-align-right material-icons" aria-hidden="true" data-wg-notranslate="">expand_less</span>
      <span v-else="" class="icon-align-right material-icons" aria-hidden="true" data-wg-notranslate="">expand_more</span>
    </div>
    <div class="list-view" v-show="unfolded">

      <div class="row list-view-entry" v-for="elem in collectionsWithPermissions" :key="elem.key">
        <div class="col-12">
          <div class="item">
            <div class="text">
              <span class="material-icons" aria-hidden="true" style="color:#67C23A; margin-right: 10px; font-size: 14px;" v-if="isContentInCollection(elem)" data-wg-notranslate="">check</span>
              <span style="word-break: break-word;">{{elem.name}}</span>
            </div>
            <div class="add-to-list-btn">
              <span class="material-icons icon-align-right" aria-hidden="true" v-if="occurrencesInCollection(elem) == 1" @click="removeFromList(elem)" data-wg-notranslate="">remove_circle_outline</span>
              <span class="material-icons icon-align-right" aria-hidden="true" v-else="" @click="addToList(elem)" data-wg-notranslate="">add_circle_outline</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
  import checkPermission from '@/utils/permission';

  export default {
    name: 'ListGroup',
    props: {
      title: {
        type: String,
        default: '',
        required: true,
      },
      content: {
        type: Object,
        default: null,
        required: true,
      },
      collections: {
        type: Array,
        default: null,
        required: true,
      },
      expanded: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data() {
      return {
        loading: false,
        unfolded: this.expanded,
      };
    },
    computed: {
      iconExpandedClass(){
        if(this.unfolded == true){
          return "el-icon-arrow-up";
        }
        return "el-icon-arrow-down";
      },
      collectionsWithPermissions(){
        var allowedCollections = [];
        for(var i = 0; i < this.collections.length; i++){
          var collection = this.collections[i];
          if(this.checkPermission({elem: collection ,permission: 'editCollections'})){
            allowedCollections.push(collection);
          }
        }
        return allowedCollections;
      },
    },
    methods: {
      checkPermission,
      isContentInCollection(collection){
        for(var i=0; i<collection.contents.length; i++){
          if(collection.contents[i].id == this.content.id){
            return true;
          }
        }
        return false;
      },
      occurrencesInCollection(collection){
        var counter = 0;
        for(var i=0; i<collection.contents.length; i++){
          if(collection.contents[i].id == this.content.id){
            counter ++;
          }
        }
        return counter;
      },
      addToList(collection){
        this.$emit('addToList',collection);
      },
      removeFromList(collection){
        this.$emit('removeFromList',collection);
      },
      toggleExpanded(){
        this.unfolded = this.unfolded == true?false:true;
      }
    },
  };

</script>

<style type="text/css">

  .list-view-group  .list-group-btn-head{
    text-align: center;
    display: table;
    margin: 0 auto;
  }

  .list-view-group .list-group-btn-head .el-button{
    width: 120px;
    margin: 0px;
  }

  .list-view-group .delete-icon{
    color:#F56C6C;
  }

  .list-view-group .list-view-group-heading{
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    border-bottom: 1px solid rgba(144, 147, 153, 0.3);
    color: #909399;
    cursor: pointer;
  }

  .list-view-group .list-view-group-heading .icon-align-right{
    text-align: right;
    float: right;
    margin-right: 10px;
  }

  .list-view-group .list-view .list-view-entry{
    padding-bottom: 10px;
  }

  .list-view-group .list-view .list-view-entry .text{
    margin-top: 9px;
    color: rgb(96, 98, 102);
    width: calc(100% - 100px);
    float: left;
  }

  .list-view-group  .list-view .list-view-entry .el-button--medium.is-circle {
    padding: 5px;
    width: auto;
  }

  .list-view-group .list-view .list-view-entry .add-to-list-btn{
    margin-top: 10px;
    cursor: pointer;
    text-align: right;
    float: left;
    width: 100px;
  }

  .list-view-group .list-view .list-view-entry .add-to-list-btn:hover{
    color: #1890ff;
  }

  .list-view-group .list-view .list-view-entry .add-to-list-btn span.material-icons{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    font-size: 18px;
  }

  

</style>
